import { useQuery } from "@tanstack/react-query";
import { getActivities } from "../../../api/activity";

export const useActivities = () => {
    const { data, isLoading, error } = useQuery({
        queryKey: ["activities"],
        queryFn: getActivities,
    });

    return { activities: data ?? [], isLoading, error };
};
