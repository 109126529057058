import { useParams } from "react-router-dom";
import { useWellnessCheck } from "../features/wellnessCheck/useWellnessCheck";
import { PartialLinkText, SpinAnimation } from "@twioku/common_components";
import { useAnswerer } from "../features/answerer/hooks/useAnswerer";
import { AnswererProfileImage } from "../components/AnswererProfileImage";
import { format } from "date-fns";

export const WellnessCheckDetailPage = () => {
    const params = useParams();
    const id = params.id as string;
    const answererId = params.answererId as string;

    const { wellnessCheck, isLoading: isWellnessCheckLoading } =
        useWellnessCheck(id);
    const { answerer, isLoading: isAnswererLoading } = useAnswerer(answererId);

    const isLoading = isWellnessCheckLoading || isAnswererLoading;

    if (isLoading || !wellnessCheck || !answerer) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );
    }

    return (
        <div className="px-4 pb-8 pt-2">
            <div className="mb-4 flex items-center gap-2">
                <AnswererProfileImage
                    answererId={answererId}
                    src={answerer.profileImagePath}
                    imageSize="medium"
                />
                <p className="text-sm">{answerer.name}</p>
            </div>

            <div className="mb-2 font-bold">
                {format(new Date(wellnessCheck.sendDate), "yyyy年MM月")}の回答
            </div>

            <ul className="flex flex-col gap-4">
                {wellnessCheck.wellnessCheckQuestions.map((question) => (
                    <li key={question.id} className="flex flex-col gap-2">
                        <div className="border-l-4 border-primary px-3 font-bold">
                            {question.wellnessCheckQuestion}
                        </div>
                        <div>
                            <PartialLinkText className="ml-4 whitespace-pre-line break-all text-base">
                                {question.respondContent}
                            </PartialLinkText>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
