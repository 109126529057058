import React from "react";
import { useActivities } from "../features/activity/hooks/useActivities";
import { SpinAnimation } from "@twioku/common_components";

export const ActivitiesPage: React.FC = () => {
    const { activities, isLoading } = useActivities();

    if (isLoading)
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );

    return (
        <div className="p-4">
            <div className="flex flex-col gap-4">
                {activities.map((activity) => (
                    <div key={activity.id} className="flex flex-col gap-2">
                        <h2 className="border-l-4 border-primary px-3 text-lg">
                            {activity.title}
                        </h2>

                        {/* FIXME: アクティビティに対する返信数に応じて表示を切り替える */}
                        <p className="px-4 text-sm text-gray-500">
                            アクティビティがありません。
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};
