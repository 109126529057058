import { useQuery } from "@tanstack/react-query";
import { getWellnessCheck } from "../../api/wellnessCheck";

export const useWellnessCheck = (id: string) => {
    const wellnessCheck = useQuery({
        queryKey: ["wellnessCheck", id],
        queryFn: () => getWellnessCheck(id),
    });

    return {
        wellnessCheck: wellnessCheck.data,
        isLoading: wellnessCheck.isLoading,
    };
};
