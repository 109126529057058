import React, { useCallback, useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMemory } from "../features/memory/hooks/useMemory";
import { AddCommentForm } from "../features/memory/components/AddCommentForm";
import { CommentList } from "../features/memory/components/CommentsList";
import { LikedButton } from "../features/memory/components/LikedButton";
import CommentIconImg from "../assets/comment_icon.svg";
import { toFormatString } from "../libs/helper";
import { VideoList } from "../components/VideoList";
import { AudioMemoryItem } from "../features/memory/components/AudioMemoryItem";
import { getDaysToReply } from "../features/memory/libs/memoryHelper";
import { AnswererProfileImage } from "../components/AnswererProfileImage";
import { useSearchParams } from "react-router-dom";
import {
    PartialLinkText,
    SpinAnimation,
    ImageList,
} from "@twioku/common_components";

export const MemoryDetail: React.FC = () => {
    const { id } = useParams();
    const {
        memory,
        newCommentText,
        newCommentImages,
        setNewCommentText,
        addComment,
        incrementLikedCount,
        setCommentImages,
        tempLikedMemoryIds,
        isLoadingAddComment,
        isMemoryLoading,
    } = useMemory(id ? id : "");
    type NavigationState = { willComment: boolean };
    const location = useLocation();
    const state = location.state as NavigationState;
    const [audioPlayed, setAudioPlayed] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const targetCommentId = searchParams.get("commentId");
    const [willComment, setWillComment] = useState<boolean>(false);
    const commentRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!commentRef?.current) return;

        if (state?.willComment || willComment) {
            commentRef.current.focus();
            setWillComment(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.willComment, willComment, commentRef.current]);

    const scrollRef = useCallback(
        (element: HTMLDivElement | null) => {
            if (element != null && state?.willComment) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        },
        [state?.willComment]
    );

    const handleClickSendCommentButton = (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();
        addComment();
    };

    const handleClickLikedButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        incrementLikedCount();
    };

    const handleClickQuoteReply = (text: string) => {
        setNewCommentText(text);
        setWillComment(true);
    };

    const daysToReplay = (): string => {
        if (!memory.respondDate || !memory.sendDate) return "";

        const respondDate = new Date(memory.respondDate);
        const sendDate = new Date(memory.sendDate);
        return getDaysToReply(respondDate, sendDate);
    };

    if (!memory || isMemoryLoading)
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );

    return (
        <main className="mx-auto h-full w-full max-w-5xl" ref={scrollRef}>
            <div className="flex h-full w-full max-w-5xl">
                <div className="mx-4 flex-1 pb-36">
                    <article className="mb-4 mt-2">
                        <section>
                            <header>
                                <div className="flex items-center justify-between">
                                    <div className="flex">
                                        <AnswererProfileImage
                                            src={
                                                memory.answererProfileImagePath
                                            }
                                            imageSize="medium"
                                            answererId={memory.answererId}
                                        />
                                        <div className="ml-2 flex items-center">
                                            <p className="text-sm">
                                                {memory.answererName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="mt-6 border-l-4 border-primary px-3 text-sm">
                                    {memory.title}
                                </h2>
                            </header>
                            {memory.memoryVoiceAudioPath && (
                                <AudioMemoryItem
                                    profileImagePath={
                                        memory.answererProfileImagePath
                                    }
                                    voiceAudioPath={memory.memoryVoiceAudioPath}
                                    onPlay={() => setAudioPlayed(true)}
                                />
                            )}
                            {memory.memoryVoiceAudioPath ? (
                                <p
                                    className={`mx-2 whitespace-pre-line p-1 text-gray-400 ${
                                        audioPlayed ? "animate-fadeInText" : ""
                                    }`}
                                >
                                    {audioPlayed && memory.respondContent}
                                </p>
                            ) : (
                                <PartialLinkText className="ml-4 whitespace-pre-line break-all pt-3">
                                    {memory.respondContent}
                                </PartialLinkText>
                            )}
                            <ImageList
                                srcList={memory.memoryImagePaths ?? []}
                            />
                            <VideoList srcList={memory.memoryVideoPaths} />
                        </section>
                    </article>
                    <div className="mb-2 ml-4 flex items-center justify-between">
                        <div>
                            <p className="mb-1 text-sm text-gray-800">
                                {memory?.respondDate
                                    ? toFormatString(
                                          new Date(memory.respondDate),
                                          false,
                                          "/"
                                      )
                                    : ""}
                            </p>
                            <p className="text-xs">{daysToReplay()}</p>
                        </div>
                        <div className="flex items-center">
                            <img
                                src={CommentIconImg}
                                className="w-6"
                                alt="comment icon"
                            />
                            <div className="ml-2 text-gray-800">
                                {memory.comments.length}
                            </div>
                            <div className="ml-2">
                                <LikedButton
                                    likedCount={memory.likedCount}
                                    disabled={tempLikedMemoryIds.includes(
                                        memory.id
                                    )}
                                    handleClick={handleClickLikedButton}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className="mb-3" />
                    <CommentList
                        comments={memory.comments}
                        answererId={memory.answererId}
                        activeCommentId={targetCommentId}
                        handleClickQuoteReply={handleClickQuoteReply}
                    />
                </div>
                <div className="fixed bottom-0 left-0 w-full bg-white">
                    <div className="mx-auto max-w-5xl">
                        <AddCommentForm
                            commentText={newCommentText}
                            imageFiles={newCommentImages}
                            handleChangeCommentText={(e) =>
                                setNewCommentText(e.target.value)
                            }
                            handleClickSendCommentButton={
                                handleClickSendCommentButton
                            }
                            handleChangeAddImageButton={setCommentImages}
                            placeholder="コメントを入力"
                            disabled={isLoadingAddComment}
                            ref={commentRef}
                        />
                    </div>
                </div>
            </div>
        </main>
    );
};
