import { forwardRef } from "react";
import { Comment } from "../../../api/generated";
import { toFormatString } from "../../../libs/helper";
import { AnswererProfileImage } from "../../../components/AnswererProfileImage";
import { UserProfileImage } from "../../../components/UserProfileImage";
import { PartialLinkText, ImageList } from "@twioku/common_components";
import { OptionMenuPopper } from "../../../components/OptionMenuPopper";

type Props = {
    comment: Comment;
    isAnswerer: boolean;
    handleClickQuoteReply: (text: string) => void;
};

export const CommentItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        talkerId,
        talkerName,
        text,
        createdAt,
        profileImagePath,
        commentImagePaths,
    } = props.comment;
    const dateCreateAt = new Date(createdAt);

    const onClickQuoteReply = () => {
        const quoteText = `> ${text}\n\n`;
        props.handleClickQuoteReply(quoteText);
    };

    const menuItems = [
        {
            text: "引用して返信",
            onClick: onClickQuoteReply,
        },
    ];

    return (
        <>
            <div className="mb-4 flex justify-between">
                {props.isAnswerer ? (
                    <>
                        <div className="mt-2 h-11 w-11 shrink-0">
                            <AnswererProfileImage
                                src={profileImagePath}
                                imageSize="medium"
                                answererId={talkerId}
                            />
                        </div>
                        <div className={`ml-5 w-full`}>
                            <div className="relative">
                                <div
                                    className={`absolute -left-1 -top-1 h-14 w-6 bg-gray-200 [clip-path:polygon(0_64%,_100%_85%,_100%_38%)]`}
                                />
                                <div
                                    ref={ref}
                                    className="ml-2 rounded-xl bg-gray-200 px-3 py-1"
                                >
                                    <p className="font-bold">{talkerName}</p>
                                    <PartialLinkText className="ml-2 block whitespace-pre-line break-all text-sm">
                                        {text}
                                    </PartialLinkText>
                                    <ImageList
                                        srcList={commentImagePaths ?? []}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-end">
                                <span className="mr-2 block text-right text-xs text-gray-500">
                                    {toFormatString(dateCreateAt, false, "/")}
                                </span>
                                <OptionMenuPopper items={menuItems} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mr-5 w-full">
                            <div className="relative">
                                <div
                                    className={`absolute -right-3 -top-1 h-14 w-6 bg-gray-200 [clip-path:polygon(100%_64%,0_85%,0_38%)]`}
                                />
                                <div className="rounded-xl bg-gray-200 px-2 py-1">
                                    <p className="font-bold">{talkerName}</p>
                                    <PartialLinkText className="ml-2 block whitespace-pre-line break-all text-sm">
                                        {text}
                                    </PartialLinkText>
                                    <ImageList
                                        srcList={commentImagePaths ?? []}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-2 block text-left text-xs text-gray-500">
                                    {toFormatString(dateCreateAt, false, "/")}
                                </span>
                                <OptionMenuPopper items={menuItems} />
                            </div>
                        </div>
                        <div className="mt-2 h-11 w-11 shrink-0">
                            <UserProfileImage
                                src={profileImagePath}
                                imageSize="medium"
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
});

CommentItem.displayName = "CommentItem";
