import { useMemo } from "react";
import featureFlags from "../../../../featureFlags.json";

export type FeatureFlags = {
    signup: boolean;
};

export const useFeatureFlags = (): FeatureFlags => {
    return useMemo(() => featureFlags, []);
};
