import React, { useState, useRef, useEffect } from "react";

type MenuItem = {
    text: string;
    onClick: () => void;
};

type OptionMenuPopperProps = {
    items: MenuItem[];
};

export const OptionMenuPopper: React.FC<OptionMenuPopperProps> = ({
    items,
}) => {
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target as Node)
        ) {
            setOpen(false);
        }
    };

    const togglePopper = () => {
        setOpen(!open);
    };

    const handleClickMenuItemCommon = (onClick: () => void) => {
        onClick();
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={containerRef} className="relative">
            <button className="text-sm" type="button" onClick={togglePopper}>
                ･･･
            </button>
            {open && (
                <div
                    className={`absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
                >
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className="cursor-pointer p-1 text-gray-500 hover:bg-gray-100"
                            onClick={() =>
                                handleClickMenuItemCommon(item.onClick)
                            }
                        >
                            {item.text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
