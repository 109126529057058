import { useQuery } from "@tanstack/react-query";
import { getGeneratedQCandidate } from "../../../../api/generatedQCandidate";
import { GenQCandidate } from "../../../../api/generated";

function useGenQCandidates() {
    const {
        data: generatedQCandidates = [],
        isLoading,
        error,
    } = useQuery<GenQCandidate[]>({
        queryKey: ["generatedQCandidates"],
        queryFn: getGeneratedQCandidate,
    });

    return {
        generatedQCandidates,
        loading: isLoading,
        error: error,
    };
}

export default useGenQCandidates;
