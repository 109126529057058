import { client } from "./defaultClient";
import { WellnessCheck } from "./generated";

export const getWellnessChecks = async (
    answererId: string
): Promise<WellnessCheck[]> => {
    return await client.getWellnessChecks({ answererId }, { mode: "cors" });
};

export const getWellnessCheck = async (id: string): Promise<WellnessCheck> => {
    return await client.getWellnessCheck({ id }, { mode: "cors" });
};
