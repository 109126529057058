/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Activity,
  Answerer,
  GenQCandidate,
  GetAuthVerify200Response,
  GetCsrfToken200Response,
  GetHello200Response,
  GetMemories200Response,
  GetMemoryMemoryIdResponseStatus200Response,
  LINEAccountLinkURL,
  Memory,
  NewAnswerer,
  NewComment,
  NewTodo,
  PatchLiffMemoryMemoryIdRequest,
  PatchUsersMeAnswerersAnswererIdRequest,
  PostAuthPasswordResetRequest,
  PostAuthSendPasswordResetEmailRequest,
  PostAuthSigninRequest,
  PostAuthSignup201Response,
  PostAuthSignupRequest,
  PostLiffAccountLinkLineEndpoint201Response,
  PostLiffAccountLinkLineEndpoint403Response,
  PostLiffAccountLinkLineEndpoint500Response,
  PostLiffAccountLinkLineEndpointRequest,
  PostLiffMemoryAnswerImage200Response,
  PostLiffMemoryUploadVideoRequest,
  PostLiffSignedurlUploadVideo200Response,
  PostLiffSignedurlUploadVideoRequest,
  PostLineAccountLinkOneTimePassword201Response,
  PostLineAccountLinkOneTimePasswordRequest,
  PostLineMemoryUploadVoiceRequest,
  PostUsersMeAnswererUploadProfileImage200Response,
  Todo,
  User,
  WellnessCheck,
} from '../models';
import {
    ActivityFromJSON,
    ActivityToJSON,
    AnswererFromJSON,
    AnswererToJSON,
    GenQCandidateFromJSON,
    GenQCandidateToJSON,
    GetAuthVerify200ResponseFromJSON,
    GetAuthVerify200ResponseToJSON,
    GetCsrfToken200ResponseFromJSON,
    GetCsrfToken200ResponseToJSON,
    GetHello200ResponseFromJSON,
    GetHello200ResponseToJSON,
    GetMemories200ResponseFromJSON,
    GetMemories200ResponseToJSON,
    GetMemoryMemoryIdResponseStatus200ResponseFromJSON,
    GetMemoryMemoryIdResponseStatus200ResponseToJSON,
    LINEAccountLinkURLFromJSON,
    LINEAccountLinkURLToJSON,
    MemoryFromJSON,
    MemoryToJSON,
    NewAnswererFromJSON,
    NewAnswererToJSON,
    NewCommentFromJSON,
    NewCommentToJSON,
    NewTodoFromJSON,
    NewTodoToJSON,
    PatchLiffMemoryMemoryIdRequestFromJSON,
    PatchLiffMemoryMemoryIdRequestToJSON,
    PatchUsersMeAnswerersAnswererIdRequestFromJSON,
    PatchUsersMeAnswerersAnswererIdRequestToJSON,
    PostAuthPasswordResetRequestFromJSON,
    PostAuthPasswordResetRequestToJSON,
    PostAuthSendPasswordResetEmailRequestFromJSON,
    PostAuthSendPasswordResetEmailRequestToJSON,
    PostAuthSigninRequestFromJSON,
    PostAuthSigninRequestToJSON,
    PostAuthSignup201ResponseFromJSON,
    PostAuthSignup201ResponseToJSON,
    PostAuthSignupRequestFromJSON,
    PostAuthSignupRequestToJSON,
    PostLiffAccountLinkLineEndpoint201ResponseFromJSON,
    PostLiffAccountLinkLineEndpoint201ResponseToJSON,
    PostLiffAccountLinkLineEndpoint403ResponseFromJSON,
    PostLiffAccountLinkLineEndpoint403ResponseToJSON,
    PostLiffAccountLinkLineEndpoint500ResponseFromJSON,
    PostLiffAccountLinkLineEndpoint500ResponseToJSON,
    PostLiffAccountLinkLineEndpointRequestFromJSON,
    PostLiffAccountLinkLineEndpointRequestToJSON,
    PostLiffMemoryAnswerImage200ResponseFromJSON,
    PostLiffMemoryAnswerImage200ResponseToJSON,
    PostLiffMemoryUploadVideoRequestFromJSON,
    PostLiffMemoryUploadVideoRequestToJSON,
    PostLiffSignedurlUploadVideo200ResponseFromJSON,
    PostLiffSignedurlUploadVideo200ResponseToJSON,
    PostLiffSignedurlUploadVideoRequestFromJSON,
    PostLiffSignedurlUploadVideoRequestToJSON,
    PostLineAccountLinkOneTimePassword201ResponseFromJSON,
    PostLineAccountLinkOneTimePassword201ResponseToJSON,
    PostLineAccountLinkOneTimePasswordRequestFromJSON,
    PostLineAccountLinkOneTimePasswordRequestToJSON,
    PostLineMemoryUploadVoiceRequestFromJSON,
    PostLineMemoryUploadVoiceRequestToJSON,
    PostUsersMeAnswererUploadProfileImage200ResponseFromJSON,
    PostUsersMeAnswererUploadProfileImage200ResponseToJSON,
    TodoFromJSON,
    TodoToJSON,
    UserFromJSON,
    UserToJSON,
    WellnessCheckFromJSON,
    WellnessCheckToJSON,
} from '../models';

export interface GetLiffAnswererRequest {
    oneTimePassword: string;
}

export interface GetLiffAnswererAnswererIdRequest {
    answererId: string;
}

export interface GetLiffMemoryMemoryIdRequest {
    memoryId: string;
}

export interface GetMemoriesRequest {
    limit: number;
    lastMemoryId?: string;
}

export interface GetMemoryMemoryIdRequest {
    memoryId: string;
}

export interface GetMemoryMemoryIdResponseStatusRequest {
    memoryId: string;
}

export interface GetTodosRequest {
    userId: string;
}

export interface GetUsersMeAnswerersAnswererIdRequest {
    answererId: string;
}

export interface GetUsersMeChangeEmailConfirmRequest {
    code: string;
}

export interface GetWellnessCheckRequest {
    id: string;
}

export interface GetWellnessChecksRequest {
    answererId: string;
}

export interface PatchLiffMemoryMemoryIdOperationRequest {
    memoryId: string;
    patchLiffMemoryMemoryIdRequest?: PatchLiffMemoryMemoryIdRequest;
}

export interface PatchLiffMemoryMemoryIdResponseStatusRequest {
    memoryId: string;
}

export interface PatchMemoryMemoryIdLikedCountRequest {
    memoryId: string;
}

export interface PatchMemoryMemoryIdResponseStatusRequest {
    memoryId: string;
}

export interface PatchUsersMeAnswerersAnswererIdOperationRequest {
    answererId: string;
    patchUsersMeAnswerersAnswererIdRequest?: PatchUsersMeAnswerersAnswererIdRequest;
}

export interface PostAuthEmailVerifyRequest {
    clientId: string;
    userName: string;
    confirmationCode: string;
    redirectUri: string;
}

export interface PostAuthPasswordResetOperationRequest {
    postAuthPasswordResetRequest?: PostAuthPasswordResetRequest;
}

export interface PostAuthSendPasswordResetEmailOperationRequest {
    postAuthSendPasswordResetEmailRequest?: PostAuthSendPasswordResetEmailRequest;
}

export interface PostAuthSigninOperationRequest {
    postAuthSigninRequest?: PostAuthSigninRequest;
}

export interface PostAuthSignupOperationRequest {
    postAuthSignupRequest?: PostAuthSignupRequest;
}

export interface PostLiffAccountLinkLineEndpointOperationRequest {
    postLiffAccountLinkLineEndpointRequest?: PostLiffAccountLinkLineEndpointRequest;
}

export interface PostLiffAnswererUploadProfileImageRequest {
    answererProfileImageByLiff: Blob;
    answererId: string;
}

export interface PostLiffMemoryAnswerImageRequest {
    memoryImageFile: Blob;
}

export interface PostLiffMemoryMemoryIdCommentsRequest {
    memoryId: string;
    newComment?: NewComment;
}

export interface PostLiffMemoryUploadVideoOperationRequest {
    postLiffMemoryUploadVideoRequest?: PostLiffMemoryUploadVideoRequest;
}

export interface PostLiffSignedurlUploadVideoOperationRequest {
    postLiffSignedurlUploadVideoRequest?: PostLiffSignedurlUploadVideoRequest;
}

export interface PostLineAccountLinkOneTimePasswordOperationRequest {
    postLineAccountLinkOneTimePasswordRequest?: PostLineAccountLinkOneTimePasswordRequest;
}

export interface PostLineMemoryUploadVoiceOperationRequest {
    postLineMemoryUploadVoiceRequest?: PostLineMemoryUploadVoiceRequest;
}

export interface PostMemoryMemoryIdCommentsRequest {
    memoryId: string;
    newComment?: NewComment;
}

export interface PostTodosRequest {
    newTodo?: NewTodo;
}

export interface PostUsersMeAnswererUploadCommentImageRequest {
    commentImageFile: Blob;
}

export interface PostUsersMeAnswererUploadProfileImageRequest {
    answererProfileImage: Blob;
    answererId: string;
}

export interface PostUsersMeAnswerersRequest {
    newAnswerer?: NewAnswerer;
}

export interface PostUsersMeAnswerersAnswererIdVerifyEmailRequest {
    answererId: string;
}

export interface PostUsersMeChangeEmailRequest {
    postAuthSendPasswordResetEmailRequest?: PostAuthSendPasswordResetEmailRequest;
}

export interface PostUsersMeQandaRequest {
    answererId: string;
    sendDate: string;
}

export interface PostUsersMeUploadProfileImageRequest {
    meProfileImage: Blob;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async getActivitiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Activity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/activities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityFromJSON));
    }

    /**
     */
    async getActivities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Activity>> {
        const response = await this.getActivitiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getAuthVerifyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAuthVerify200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/verify`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuthVerify200ResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getAuthVerify(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAuthVerify200Response> {
        const response = await this.getAuthVerifyRaw(initOverrides);
        return await response.value();
    }

    /**
     * get csrf token
     */
    async getCsrfTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCsrfToken200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/csrf-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCsrfToken200ResponseFromJSON(jsonValue));
    }

    /**
     * get csrf token
     */
    async getCsrfToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCsrfToken200Response> {
        const response = await this.getCsrfTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * get all generated Question candidate by user
     * get generated q candidate authenticated user
     */
    async getGenQcandidateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GenQCandidate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/generatedQCandidate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GenQCandidateFromJSON));
    }

    /**
     * get all generated Question candidate by user
     * get generated q candidate authenticated user
     */
    async getGenQcandidate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GenQCandidate>> {
        const response = await this.getGenQcandidateRaw(initOverrides);
        return await response.value();
    }

    /**
     * hello world
     */
    async getHelloRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetHello200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hello`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHello200ResponseFromJSON(jsonValue));
    }

    /**
     * hello world
     */
    async getHello(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetHello200Response> {
        const response = await this.getHelloRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     * get-answerer
     */
    async getLiffAnswererRaw(requestParameters: GetLiffAnswererRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Answerer>> {
        if (requestParameters.oneTimePassword === null || requestParameters.oneTimePassword === undefined) {
            throw new runtime.RequiredError('oneTimePassword','Required parameter requestParameters.oneTimePassword was null or undefined when calling getLiffAnswerer.');
        }

        const queryParameters: any = {};

        if (requestParameters.oneTimePassword !== undefined) {
            queryParameters['oneTimePassword'] = requestParameters.oneTimePassword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/liff/answerer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnswererFromJSON(jsonValue));
    }

    /**
     * 
     * get-answerer
     */
    async getLiffAnswerer(requestParameters: GetLiffAnswererRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Answerer> {
        const response = await this.getLiffAnswererRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get answerer for liff
     */
    async getLiffAnswererAnswererIdRaw(requestParameters: GetLiffAnswererAnswererIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Answerer>> {
        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling getLiffAnswererAnswererId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/liff/answerer/{answererId}`.replace(`{${"answererId"}}`, encodeURIComponent(String(requestParameters.answererId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnswererFromJSON(jsonValue));
    }

    /**
     * get answerer for liff
     */
    async getLiffAnswererAnswererId(requestParameters: GetLiffAnswererAnswererIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Answerer> {
        const response = await this.getLiffAnswererAnswererIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * get answerers
     */
    async getLiffAnswerersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Answerer>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/liff/answerers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AnswererFromJSON));
    }

    /**
     * 
     * get answerers
     */
    async getLiffAnswerers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Answerer>> {
        const response = await this.getLiffAnswerersRaw(initOverrides);
        return await response.value();
    }

    /**
     * get a memory for liff
     * get a memory for liff
     */
    async getLiffMemoryMemoryIdRaw(requestParameters: GetLiffMemoryMemoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Memory>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling getLiffMemoryMemoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/liff/memory/{memoryId}`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryFromJSON(jsonValue));
    }

    /**
     * get a memory for liff
     * get a memory for liff
     */
    async getLiffMemoryMemoryId(requestParameters: GetLiffMemoryMemoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Memory> {
        const response = await this.getLiffMemoryMemoryIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get pagenation answerer\'s memories
     * Your GET endpoint
     */
    async getMemoriesRaw(requestParameters: GetMemoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMemories200Response>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getMemories.');
        }

        const queryParameters: any = {};

        if (requestParameters.lastMemoryId !== undefined) {
            queryParameters['lastMemoryId'] = requestParameters.lastMemoryId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/memories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemories200ResponseFromJSON(jsonValue));
    }

    /**
     * get pagenation answerer\'s memories
     * Your GET endpoint
     */
    async getMemories(requestParameters: GetMemoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMemories200Response> {
        const response = await this.getMemoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get memory
     * get Memory
     */
    async getMemoryMemoryIdRaw(requestParameters: GetMemoryMemoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Memory>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling getMemoryMemoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/memory/{memoryId}`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryFromJSON(jsonValue));
    }

    /**
     * get memory
     * get Memory
     */
    async getMemoryMemoryId(requestParameters: GetMemoryMemoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Memory> {
        const response = await this.getMemoryMemoryIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get responseStatus on Memory
     * get responseStatus on Memory
     */
    async getMemoryMemoryIdResponseStatusRaw(requestParameters: GetMemoryMemoryIdResponseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMemoryMemoryIdResponseStatus200Response>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling getMemoryMemoryIdResponseStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/memory/{memoryId}/responseStatus`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemoryMemoryIdResponseStatus200ResponseFromJSON(jsonValue));
    }

    /**
     * get responseStatus on Memory
     * get responseStatus on Memory
     */
    async getMemoryMemoryIdResponseStatus(requestParameters: GetMemoryMemoryIdResponseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMemoryMemoryIdResponseStatus200Response> {
        const response = await this.getMemoryMemoryIdResponseStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getTodosRaw(requestParameters: GetTodosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Todo>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getTodos.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/todos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TodoFromJSON));
    }

    /**
     * Your GET endpoint
     */
    async getTodos(requestParameters: GetTodosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Todo>> {
        const response = await this.getTodosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async getUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async getUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * get your answerers
     */
    async getUsersMeAnswerersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Answerer>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/me/answerers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AnswererFromJSON));
    }

    /**
     * get your answerers
     */
    async getUsersMeAnswerers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Answerer>> {
        const response = await this.getUsersMeAnswerersRaw(initOverrides);
        return await response.value();
    }

    /**
     * get your answerer
     * get your answerer
     */
    async getUsersMeAnswerersAnswererIdRaw(requestParameters: GetUsersMeAnswerersAnswererIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Answerer>> {
        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling getUsersMeAnswerersAnswererId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/me/answerers/{answererId}`.replace(`{${"answererId"}}`, encodeURIComponent(String(requestParameters.answererId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnswererFromJSON(jsonValue));
    }

    /**
     * get your answerer
     * get your answerer
     */
    async getUsersMeAnswerersAnswererId(requestParameters: GetUsersMeAnswerersAnswererIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Answerer> {
        const response = await this.getUsersMeAnswerersAnswererIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * change email confirm
     */
    async getUsersMeChangeEmailConfirmRaw(requestParameters: GetUsersMeChangeEmailConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostAuthSignup201Response>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getUsersMeChangeEmailConfirm.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/me/change-email-confirm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAuthSignup201ResponseFromJSON(jsonValue));
    }

    /**
     * change email confirm
     */
    async getUsersMeChangeEmailConfirm(requestParameters: GetUsersMeChangeEmailConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostAuthSignup201Response> {
        const response = await this.getUsersMeChangeEmailConfirmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getWellnessCheckRaw(requestParameters: GetWellnessCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellnessCheck>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getWellnessCheck.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/wellness-checks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellnessCheckFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getWellnessCheck(requestParameters: GetWellnessCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellnessCheck> {
        const response = await this.getWellnessCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getWellnessChecksRaw(requestParameters: GetWellnessChecksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WellnessCheck>>> {
        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling getWellnessChecks.');
        }

        const queryParameters: any = {};

        if (requestParameters.answererId !== undefined) {
            queryParameters['answererId'] = requestParameters.answererId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/wellness-checks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WellnessCheckFromJSON));
    }

    /**
     * 
     */
    async getWellnessChecks(requestParameters: GetWellnessChecksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WellnessCheck>> {
        const response = await this.getWellnessChecksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * answer memory for liff
     */
    async patchLiffMemoryMemoryIdRaw(requestParameters: PatchLiffMemoryMemoryIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Memory>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling patchLiffMemoryMemoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/liff/memory/{memoryId}`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchLiffMemoryMemoryIdRequestToJSON(requestParameters.patchLiffMemoryMemoryIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryFromJSON(jsonValue));
    }

    /**
     * answer memory for liff
     */
    async patchLiffMemoryMemoryId(requestParameters: PatchLiffMemoryMemoryIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Memory> {
        const response = await this.patchLiffMemoryMemoryIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async patchLiffMemoryMemoryIdResponseStatusRaw(requestParameters: PatchLiffMemoryMemoryIdResponseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling patchLiffMemoryMemoryIdResponseStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/liff/memory/{memoryId}/responseStatus`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async patchLiffMemoryMemoryIdResponseStatus(requestParameters: PatchLiffMemoryMemoryIdResponseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchLiffMemoryMemoryIdResponseStatusRaw(requestParameters, initOverrides);
    }

    /**
     * increment likedCount
     */
    async patchMemoryMemoryIdLikedCountRaw(requestParameters: PatchMemoryMemoryIdLikedCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Memory>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling patchMemoryMemoryIdLikedCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/memory/{memoryId}/likedCount`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryFromJSON(jsonValue));
    }

    /**
     * increment likedCount
     */
    async patchMemoryMemoryIdLikedCount(requestParameters: PatchMemoryMemoryIdLikedCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Memory> {
        const response = await this.patchMemoryMemoryIdLikedCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Make memory\'responseStatus NO_ANSWER 
     * Make memory-responseStatus NO_ANSWER
     */
    async patchMemoryMemoryIdResponseStatusRaw(requestParameters: PatchMemoryMemoryIdResponseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling patchMemoryMemoryIdResponseStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/memory/{memoryId}/responseStatus`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Make memory\'responseStatus NO_ANSWER 
     * Make memory-responseStatus NO_ANSWER
     */
    async patchMemoryMemoryIdResponseStatus(requestParameters: PatchMemoryMemoryIdResponseStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchMemoryMemoryIdResponseStatusRaw(requestParameters, initOverrides);
    }

    /**
     * update answerer
     * update answerer
     */
    async patchUsersMeAnswerersAnswererIdRaw(requestParameters: PatchUsersMeAnswerersAnswererIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Answerer>> {
        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling patchUsersMeAnswerersAnswererId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/me/answerers/{answererId}`.replace(`{${"answererId"}}`, encodeURIComponent(String(requestParameters.answererId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchUsersMeAnswerersAnswererIdRequestToJSON(requestParameters.patchUsersMeAnswerersAnswererIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnswererFromJSON(jsonValue));
    }

    /**
     * update answerer
     * update answerer
     */
    async patchUsersMeAnswerersAnswererId(requestParameters: PatchUsersMeAnswerersAnswererIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Answerer> {
        const response = await this.patchUsersMeAnswerersAnswererIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * verify email
     */
    async postAuthEmailVerifyRaw(requestParameters: PostAuthEmailVerifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling postAuthEmailVerify.');
        }

        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError('userName','Required parameter requestParameters.userName was null or undefined when calling postAuthEmailVerify.');
        }

        if (requestParameters.confirmationCode === null || requestParameters.confirmationCode === undefined) {
            throw new runtime.RequiredError('confirmationCode','Required parameter requestParameters.confirmationCode was null or undefined when calling postAuthEmailVerify.');
        }

        if (requestParameters.redirectUri === null || requestParameters.redirectUri === undefined) {
            throw new runtime.RequiredError('redirectUri','Required parameter requestParameters.redirectUri was null or undefined when calling postAuthEmailVerify.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.userName !== undefined) {
            queryParameters['user_name'] = requestParameters.userName;
        }

        if (requestParameters.confirmationCode !== undefined) {
            queryParameters['confirmation_code'] = requestParameters.confirmationCode;
        }

        if (requestParameters.redirectUri !== undefined) {
            queryParameters['redirect_uri'] = requestParameters.redirectUri;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/verify-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * verify email
     */
    async postAuthEmailVerify(requestParameters: PostAuthEmailVerifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postAuthEmailVerifyRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postAuthPasswordResetRaw(requestParameters: PostAuthPasswordResetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/password-reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAuthPasswordResetRequestToJSON(requestParameters.postAuthPasswordResetRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postAuthPasswordReset(requestParameters: PostAuthPasswordResetOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postAuthPasswordResetRaw(requestParameters, initOverrides);
    }

    /**
     * 
     * 
     */
    async postAuthSendPasswordResetEmailRaw(requestParameters: PostAuthSendPasswordResetEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/send-password-reset-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAuthSendPasswordResetEmailRequestToJSON(requestParameters.postAuthSendPasswordResetEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * 
     */
    async postAuthSendPasswordResetEmail(requestParameters: PostAuthSendPasswordResetEmailOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postAuthSendPasswordResetEmailRaw(requestParameters, initOverrides);
    }

    /**
     * signin
     */
    async postAuthSigninRaw(requestParameters: PostAuthSigninOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostAuthSignup201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAuthSigninRequestToJSON(requestParameters.postAuthSigninRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAuthSignup201ResponseFromJSON(jsonValue));
    }

    /**
     * signin
     */
    async postAuthSignin(requestParameters: PostAuthSigninOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostAuthSignup201Response> {
        const response = await this.postAuthSigninRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * signout
     */
    async postAuthSignoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostAuthSignup201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/signout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAuthSignup201ResponseFromJSON(jsonValue));
    }

    /**
     * signout
     */
    async postAuthSignout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostAuthSignup201Response> {
        const response = await this.postAuthSignoutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Signup
     */
    async postAuthSignupRaw(requestParameters: PostAuthSignupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostAuthSignup201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAuthSignupRequestToJSON(requestParameters.postAuthSignupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAuthSignup201ResponseFromJSON(jsonValue));
    }

    /**
     * Signup
     */
    async postAuthSignup(requestParameters: PostAuthSignupOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostAuthSignup201Response> {
        const response = await this.postAuthSignupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postHelloRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetHello200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hello`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHello200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postHello(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetHello200Response> {
        const response = await this.postHelloRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postLiffAccountLinkLineEndpointRaw(requestParameters: PostLiffAccountLinkLineEndpointOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LINEAccountLinkURL>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/liff/account-link/line-endpoint`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLiffAccountLinkLineEndpointRequestToJSON(requestParameters.postLiffAccountLinkLineEndpointRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LINEAccountLinkURLFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postLiffAccountLinkLineEndpoint(requestParameters: PostLiffAccountLinkLineEndpointOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LINEAccountLinkURL> {
        const response = await this.postLiffAccountLinkLineEndpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postLiffAnswererUploadProfileImageRaw(requestParameters: PostLiffAnswererUploadProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLiffSignedurlUploadVideoRequest>> {
        if (requestParameters.answererProfileImageByLiff === null || requestParameters.answererProfileImageByLiff === undefined) {
            throw new runtime.RequiredError('answererProfileImageByLiff','Required parameter requestParameters.answererProfileImageByLiff was null or undefined when calling postLiffAnswererUploadProfileImage.');
        }

        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling postLiffAnswererUploadProfileImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.answererProfileImageByLiff !== undefined) {
            formParams.append('answererProfileImageByLiff', requestParameters.answererProfileImageByLiff as any);
        }

        if (requestParameters.answererId !== undefined) {
            formParams.append('answererId', requestParameters.answererId as any);
        }

        const response = await this.request({
            path: `/liff/answerer/upload-profile-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLiffSignedurlUploadVideoRequestFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postLiffAnswererUploadProfileImage(requestParameters: PostLiffAnswererUploadProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLiffSignedurlUploadVideoRequest> {
        const response = await this.postLiffAnswererUploadProfileImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * answer memory image for liff
     */
    async postLiffMemoryAnswerImageRaw(requestParameters: PostLiffMemoryAnswerImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLiffMemoryAnswerImage200Response>> {
        if (requestParameters.memoryImageFile === null || requestParameters.memoryImageFile === undefined) {
            throw new runtime.RequiredError('memoryImageFile','Required parameter requestParameters.memoryImageFile was null or undefined when calling postLiffMemoryAnswerImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.memoryImageFile !== undefined) {
            formParams.append('memoryImageFile', requestParameters.memoryImageFile as any);
        }

        const response = await this.request({
            path: `/liff/memory/answer/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLiffMemoryAnswerImage200ResponseFromJSON(jsonValue));
    }

    /**
     * answer memory image for liff
     */
    async postLiffMemoryAnswerImage(requestParameters: PostLiffMemoryAnswerImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLiffMemoryAnswerImage200Response> {
        const response = await this.postLiffMemoryAnswerImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postLiffMemoryMemoryIdCommentsRaw(requestParameters: PostLiffMemoryMemoryIdCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Memory>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling postLiffMemoryMemoryIdComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/liff/memory/{memoryId}/comments`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCommentToJSON(requestParameters.newComment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postLiffMemoryMemoryIdComments(requestParameters: PostLiffMemoryMemoryIdCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Memory> {
        const response = await this.postLiffMemoryMemoryIdCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postLiffMemoryUploadVideoRaw(requestParameters: PostLiffMemoryUploadVideoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/liff/memory/upload/video`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLiffMemoryUploadVideoRequestToJSON(requestParameters.postLiffMemoryUploadVideoRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postLiffMemoryUploadVideo(requestParameters: PostLiffMemoryUploadVideoOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postLiffMemoryUploadVideoRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postLiffSignedurlUploadVideoRaw(requestParameters: PostLiffSignedurlUploadVideoOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLiffSignedurlUploadVideo200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/liff/signedurl/upload/video`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLiffSignedurlUploadVideoRequestToJSON(requestParameters.postLiffSignedurlUploadVideoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLiffSignedurlUploadVideo200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postLiffSignedurlUploadVideo(requestParameters: PostLiffSignedurlUploadVideoOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLiffSignedurlUploadVideo200Response> {
        const response = await this.postLiffSignedurlUploadVideoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postLineAccountLinkOneTimePasswordRaw(requestParameters: PostLineAccountLinkOneTimePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLineAccountLinkOneTimePassword201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/line/account-link/one-time-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLineAccountLinkOneTimePasswordRequestToJSON(requestParameters.postLineAccountLinkOneTimePasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLineAccountLinkOneTimePassword201ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postLineAccountLinkOneTimePassword(requestParameters: PostLineAccountLinkOneTimePasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLineAccountLinkOneTimePassword201Response> {
        const response = await this.postLineAccountLinkOneTimePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postLineMemoryUploadVoiceRaw(requestParameters: PostLineMemoryUploadVoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/liff/memory/upload/voice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLineMemoryUploadVoiceRequestToJSON(requestParameters.postLineMemoryUploadVoiceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postLineMemoryUploadVoice(requestParameters: PostLineMemoryUploadVoiceOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postLineMemoryUploadVoiceRaw(requestParameters, initOverrides);
    }

    /**
     * add comment on memory
     */
    async postMemoryMemoryIdCommentsRaw(requestParameters: PostMemoryMemoryIdCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Memory>> {
        if (requestParameters.memoryId === null || requestParameters.memoryId === undefined) {
            throw new runtime.RequiredError('memoryId','Required parameter requestParameters.memoryId was null or undefined when calling postMemoryMemoryIdComments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/memory/{memoryId}/comments`.replace(`{${"memoryId"}}`, encodeURIComponent(String(requestParameters.memoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCommentToJSON(requestParameters.newComment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryFromJSON(jsonValue));
    }

    /**
     * add comment on memory
     */
    async postMemoryMemoryIdComments(requestParameters: PostMemoryMemoryIdCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Memory> {
        const response = await this.postMemoryMemoryIdCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postTodosRaw(requestParameters: PostTodosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/todos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewTodoToJSON(requestParameters.newTodo),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 
     */
    async postTodos(requestParameters: PostTodosRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.postTodosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postUsersMeAnswererUploadCommentImageRaw(requestParameters: PostUsersMeAnswererUploadCommentImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostUsersMeAnswererUploadProfileImage200Response>> {
        if (requestParameters.commentImageFile === null || requestParameters.commentImageFile === undefined) {
            throw new runtime.RequiredError('commentImageFile','Required parameter requestParameters.commentImageFile was null or undefined when calling postUsersMeAnswererUploadCommentImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.commentImageFile !== undefined) {
            formParams.append('commentImageFile', requestParameters.commentImageFile as any);
        }

        const response = await this.request({
            path: `/users/me/answerer/upload-comment-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostUsersMeAnswererUploadProfileImage200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postUsersMeAnswererUploadCommentImage(requestParameters: PostUsersMeAnswererUploadCommentImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostUsersMeAnswererUploadProfileImage200Response> {
        const response = await this.postUsersMeAnswererUploadCommentImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * upload to answerer image
     * upload to answerer image
     */
    async postUsersMeAnswererUploadProfileImageRaw(requestParameters: PostUsersMeAnswererUploadProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostUsersMeAnswererUploadProfileImage200Response>> {
        if (requestParameters.answererProfileImage === null || requestParameters.answererProfileImage === undefined) {
            throw new runtime.RequiredError('answererProfileImage','Required parameter requestParameters.answererProfileImage was null or undefined when calling postUsersMeAnswererUploadProfileImage.');
        }

        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling postUsersMeAnswererUploadProfileImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.answererProfileImage !== undefined) {
            formParams.append('answererProfileImage', requestParameters.answererProfileImage as any);
        }

        if (requestParameters.answererId !== undefined) {
            formParams.append('answererId', requestParameters.answererId as any);
        }

        const response = await this.request({
            path: `/users/me/answerer/upload-profile-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostUsersMeAnswererUploadProfileImage200ResponseFromJSON(jsonValue));
    }

    /**
     * upload to answerer image
     * upload to answerer image
     */
    async postUsersMeAnswererUploadProfileImage(requestParameters: PostUsersMeAnswererUploadProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostUsersMeAnswererUploadProfileImage200Response> {
        const response = await this.postUsersMeAnswererUploadProfileImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * register answerer
     */
    async postUsersMeAnswerersRaw(requestParameters: PostUsersMeAnswerersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Answerer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/me/answerers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewAnswererToJSON(requestParameters.newAnswerer),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnswererFromJSON(jsonValue));
    }

    /**
     * 
     * register answerer
     */
    async postUsersMeAnswerers(requestParameters: PostUsersMeAnswerersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Answerer> {
        const response = await this.postUsersMeAnswerersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * validate Answerer\'s email address.
     * 
     */
    async postUsersMeAnswerersAnswererIdVerifyEmailRaw(requestParameters: PostUsersMeAnswerersAnswererIdVerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling postUsersMeAnswerersAnswererIdVerifyEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/me/answerers/{answererId}/verify-email`.replace(`{${"answererId"}}`, encodeURIComponent(String(requestParameters.answererId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * validate Answerer\'s email address.
     * 
     */
    async postUsersMeAnswerersAnswererIdVerifyEmail(requestParameters: PostUsersMeAnswerersAnswererIdVerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersMeAnswerersAnswererIdVerifyEmailRaw(requestParameters, initOverrides);
    }

    /**
     * change email
     */
    async postUsersMeChangeEmailRaw(requestParameters: PostUsersMeChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostAuthSignup201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/me/change-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAuthSendPasswordResetEmailRequestToJSON(requestParameters.postAuthSendPasswordResetEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAuthSignup201ResponseFromJSON(jsonValue));
    }

    /**
     * change email
     */
    async postUsersMeChangeEmail(requestParameters: PostUsersMeChangeEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostAuthSignup201Response> {
        const response = await this.postUsersMeChangeEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update lastAccessedDatetime
     * update lastAccessedDatetime
     */
    async postUsersMeLastAccessedDatetimeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/me/lastAccessedDatetime`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update lastAccessedDatetime
     * update lastAccessedDatetime
     */
    async postUsersMeLastAccessedDatetime(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersMeLastAccessedDatetimeRaw(initOverrides);
    }

    /**
     * 
     * like QandA
     */
    async postUsersMeQandaRaw(requestParameters: PostUsersMeQandaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Memory>> {
        if (requestParameters.answererId === null || requestParameters.answererId === undefined) {
            throw new runtime.RequiredError('answererId','Required parameter requestParameters.answererId was null or undefined when calling postUsersMeQanda.');
        }

        if (requestParameters.sendDate === null || requestParameters.sendDate === undefined) {
            throw new runtime.RequiredError('sendDate','Required parameter requestParameters.sendDate was null or undefined when calling postUsersMeQanda.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/me/qanda/{answererId}/{sendDate}/like`.replace(`{${"answererId"}}`, encodeURIComponent(String(requestParameters.answererId))).replace(`{${"sendDate"}}`, encodeURIComponent(String(requestParameters.sendDate))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemoryFromJSON(jsonValue));
    }

    /**
     * 
     * like QandA
     */
    async postUsersMeQanda(requestParameters: PostUsersMeQandaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Memory> {
        const response = await this.postUsersMeQandaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * user profile image upload
     * 
     */
    async postUsersMeUploadProfileImageRaw(requestParameters: PostUsersMeUploadProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostUsersMeAnswererUploadProfileImage200Response>> {
        if (requestParameters.meProfileImage === null || requestParameters.meProfileImage === undefined) {
            throw new runtime.RequiredError('meProfileImage','Required parameter requestParameters.meProfileImage was null or undefined when calling postUsersMeUploadProfileImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.meProfileImage !== undefined) {
            formParams.append('meProfileImage', requestParameters.meProfileImage as any);
        }

        const response = await this.request({
            path: `/users/me/upload-profile-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostUsersMeAnswererUploadProfileImage200ResponseFromJSON(jsonValue));
    }

    /**
     * user profile image upload
     * 
     */
    async postUsersMeUploadProfileImage(requestParameters: PostUsersMeUploadProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostUsersMeAnswererUploadProfileImage200Response> {
        const response = await this.postUsersMeUploadProfileImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
