import { Comment } from "../../../api/generated";
import { CommentItem } from "./CommentItem";
import { useEffect, useRef } from "react";

type Props = {
    comments: Comment[];
    answererId: string;
    activeCommentId?: string | null;
    handleClickQuoteReply: (text: string) => void;
};

export const CommentList: React.FC<Props> = (props: Props) => {
    const { comments, answererId, activeCommentId } = props;
    const itemRefs = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        if (activeCommentId) {
            const index = comments.findIndex(
                (item) => item.id === activeCommentId
            );
            const targetElement = itemRefs.current[index] as HTMLElement;
            if (index !== -1 && targetElement) {
                // 画面内に表示されるようスクロール
                targetElement.scrollIntoView({ behavior: "smooth" });
                // 対象のコメントにスタイルを追加
                const classesToAdd = ["border-2", "border-primary"];
                classesToAdd.forEach((cls) => {
                    if (!targetElement.classList.contains(cls)) {
                        targetElement.classList.add(cls);
                    }
                });
            }
        }
    }, [activeCommentId, comments]);

    return (
        <>
            {comments.length === 0 ? (
                <div className="my-auto text-center text-2xl text-gray-300 ">
                    <p className="whitespace-pre-line">
                        記憶を見て感じたことを
                        <br />
                        コメントしましょう！
                    </p>
                </div>
            ) : (
                <ul>
                    {comments.map((comment, index) => (
                        <li key={index}>
                            <div className="mb-2">
                                <CommentItem
                                    comment={comment}
                                    isAnswerer={comment.talkerId === answererId}
                                    ref={(element: HTMLDivElement) =>
                                        (itemRefs.current[index] = element)
                                    }
                                    handleClickQuoteReply={
                                        props.handleClickQuoteReply
                                    }
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};
