import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getWellnessChecks } from "../../api/wellnessCheck";

export const useWellnessChecks = (answererId: string) => {
    const queryKey = useMemo(
        () => ["wellnessChecks", answererId],
        [answererId]
    );
    const queryClient = useQueryClient();

    const { data: wellnessChecks, isLoading } = useQuery(queryKey, () =>
        getWellnessChecks(answererId)
    );

    const clearCache = useCallback(async () => {
        await queryClient.invalidateQueries(queryKey);
    }, [queryClient, queryKey]);

    return { wellnessChecks: wellnessChecks ?? [], isLoading, clearCache };
};
