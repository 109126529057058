import React from "react";
import HomeButtonImg from "../assets/home_icon.svg";
import UsersButtonImg from "../assets/users_icon.svg";
import UserButtonImg from "../assets/user_icon.svg";
import EmailButtonImg from "../assets/email.svg";
import ExternalLinkImg from "../assets/external_link.svg";
// FIXME: 本番リリース用に一旦ユーザーからは見えないようにしておく。
// import WellnessCheckImg from "../assets/wellness_check.svg";
// import TargetImg from "../assets/target_icon.svg";
import { Path } from "../routes/PagePath";
import { Link, useNavigate } from "react-router-dom";
import { useHamburgerMenuInfo } from "../features/hamburgerMenu/useHamburgerMenuInfo";
import { useAuth } from "../features/auth/hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";

export const HamburgerMenu: React.FC = () => {
    const navigate = useNavigate();
    const { signoutUser } = useAuth();
    const { hamburgerMenuClose } = useHamburgerMenuInfo();
    const queryClient = useQueryClient();

    const hamburgerMenuItems = [
        { name: "ホーム", path: Path.root, img: HomeButtonImg },
        { name: "やりとり相手一覧", path: Path.answerers, img: UsersButtonImg },
        { name: "プロフィール", path: Path.profile, img: UserButtonImg },
        // FIXME: 本番リリース用に一旦ユーザーからは見えないようにしておく。
        // { name: "アクティビティ", path: Path.activities, img: TargetImg },
        // {
        //     name: "親の様子アンケート",
        //     path: Path.wellnessChecksAnswerers,
        //     img: WellnessCheckImg,
        // },
        {
            name: "お問い合わせ",
            path: "https://redjourney.jp/contact/",
            img: EmailButtonImg,
        },
    ];

    // 外部パスか判定
    const isExternalPath = (path: string) => {
        return !Object.values(Path).includes(path);
    };

    const handleClick = async (path: string) => {
        hamburgerMenuClose();
        if (isExternalPath(path)) {
            window.open(path, "_blank");
        } else {
            navigate(path);
        }
    };

    const handleClickLogout = async () => {
        await signoutUser().then(() => queryClient.clear());
    };

    return (
        <nav>
            <ul>
                {hamburgerMenuItems.map((hamburgerMenuItem) => (
                    <li
                        key={hamburgerMenuItem.name}
                        className="m-4 mx-5"
                        onClick={() => handleClick(hamburgerMenuItem.path)}
                    >
                        <button className="flex">
                            <img
                                src={hamburgerMenuItem.img}
                                alt={hamburgerMenuItem.img}
                            />
                            <span className="ml-4 flex">
                                {hamburgerMenuItem.name}
                                {isExternalPath(hamburgerMenuItem.path) && (
                                    <img
                                        src={ExternalLinkImg}
                                        className="ml-1"
                                    />
                                )}
                            </span>
                        </button>
                    </li>
                ))}
            </ul>
            <hr className="my-4" />
            <ul>
                <li className="m-4 mx-5">
                    <button
                        className="text-sm text-primary underline"
                        onClick={handleClickLogout}
                    >
                        ログアウト
                    </button>
                </li>
            </ul>
            <hr />
            <ul className="mx-5 text-xs text-primary underline">
                <li className="mt-2">
                    <Link
                        to={Path.terms}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        利用規約
                    </Link>
                </li>
            </ul>
        </nav>
    );
};
