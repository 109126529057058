/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WellnessCheckQuestion } from './WellnessCheckQuestion';
import {
    WellnessCheckQuestionFromJSON,
    WellnessCheckQuestionFromJSONTyped,
    WellnessCheckQuestionToJSON,
} from './WellnessCheckQuestion';

/**
 * 
 * @export
 * @interface WellnessCheck
 */
export interface WellnessCheck {
    /**
     * 
     * @type {string}
     * @memberof WellnessCheck
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WellnessCheck
     */
    sendDate: string;
    /**
     * 
     * @type {string}
     * @memberof WellnessCheck
     */
    respondDate: string;
    /**
     * 
     * @type {Array<WellnessCheckQuestion>}
     * @memberof WellnessCheck
     */
    wellnessCheckQuestions: Array<WellnessCheckQuestion>;
}

/**
 * Check if a given object implements the WellnessCheck interface.
 */
export function instanceOfWellnessCheck(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sendDate" in value;
    isInstance = isInstance && "respondDate" in value;
    isInstance = isInstance && "wellnessCheckQuestions" in value;

    return isInstance;
}

export function WellnessCheckFromJSON(json: any): WellnessCheck {
    return WellnessCheckFromJSONTyped(json, false);
}

export function WellnessCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellnessCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sendDate': json['sendDate'],
        'respondDate': json['respondDate'],
        'wellnessCheckQuestions': ((json['wellnessCheckQuestions'] as Array<any>).map(WellnessCheckQuestionFromJSON)),
    };
}

export function WellnessCheckToJSON(value?: WellnessCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sendDate': value.sendDate,
        'respondDate': value.respondDate,
        'wellnessCheckQuestions': ((value.wellnessCheckQuestions as Array<any>).map(WellnessCheckQuestionToJSON)),
    };
}

