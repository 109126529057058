/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Generated Question Candidate
 * @export
 * @interface GenQCandidate
 */
export interface GenQCandidate {
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    answererId: string;
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenQCandidate
     */
    originMemoryIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    score: string;
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    scoreDescription: string;
    /**
     * 
     * @type {string}
     * @memberof GenQCandidate
     */
    createdAt: string;
}

/**
 * Check if a given object implements the GenQCandidate interface.
 */
export function instanceOfGenQCandidate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "answererId" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "originMemoryIds" in value;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "scoreDescription" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function GenQCandidateFromJSON(json: any): GenQCandidate {
    return GenQCandidateFromJSONTyped(json, false);
}

export function GenQCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenQCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'answererId': json['answererId'],
        'question': json['question'],
        'description': json['description'],
        'originMemoryIds': json['originMemoryIds'],
        'score': json['score'],
        'scoreDescription': json['scoreDescription'],
        'createdAt': json['createdAt'],
    };
}

export function GenQCandidateToJSON(value?: GenQCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'answererId': value.answererId,
        'question': value.question,
        'description': value.description,
        'originMemoryIds': value.originMemoryIds,
        'score': value.score,
        'scoreDescription': value.scoreDescription,
        'createdAt': value.createdAt,
    };
}

