import { useFeatureFlags } from "./useFeatureFlag";

export type FeatureFlagName = "signup";

export const useFeatureEnabled = (
    featureFlagName: FeatureFlagName
): boolean => {
    const featureFlags = useFeatureFlags();

    if (featureFlagName === "signup") {
        return featureFlags.signup;
    }

    return false;
};
