import type { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useWellnessChecks } from "../features/wellnessCheck/useWellnessChecks";
import { SpinAnimation } from "@twioku/common_components";
import { useAnswerer } from "../features/answerer/hooks/useAnswerer";
import { AnswererProfileImage } from "../components/AnswererProfileImage";
import { format } from "date-fns";

export const WellnessChecksPage: FC = () => {
    const answererId = useParams().answererId as string;
    const { answerer, isLoading: isAnswererLoading } = useAnswerer(answererId);

    const { wellnessChecks, isLoading: isWellnessChecksLoading } =
        useWellnessChecks(answererId);

    const isLoading = isAnswererLoading || isWellnessChecksLoading;

    if (isLoading || !answerer) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );
    }

    return (
        <div className="px-4 pb-8 pt-2">
            <div className="mb-4 flex items-center gap-2">
                <AnswererProfileImage
                    answererId={answererId}
                    src={answerer.profileImagePath}
                    imageSize="medium"
                />
                <p className="text-sm">{answerer.name}</p>
            </div>

            {wellnessChecks.length === 0 && (
                <div className="flex items-center justify-center">
                    <p>まだ回答がありません</p>
                </div>
            )}

            <ul className="flex flex-col gap-4">
                {wellnessChecks.map((wellnessCheck) => (
                    <li key={wellnessCheck.id}>
                        <Link
                            to={`/wellness-checks/answerer/${answererId}/${wellnessCheck.id}`}
                        >
                            <div className="mb-2 text-lg">
                                {format(
                                    new Date(wellnessCheck.sendDate),
                                    "yyyy年MM月"
                                )}
                                の回答
                            </div>
                        </Link>
                        <hr className="border-gray-200" />
                    </li>
                ))}
            </ul>
        </div>
    );
};
